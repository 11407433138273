<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";

export default defineComponent({
  components: {
    AuthenticatedLayout,
  },
  setup() {
    const layout = computed(() => {
      return "AuthenticatedLayout";
    });

    return { layout };
  },
});
</script>
