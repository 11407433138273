import { apiClient } from "@/services/api";
import { User } from "@/interfaces/auth.types";
import { LoginResponse } from "@/interfaces/responses.types";

/**
 * Login function
 *
 * @param {string} email
 * @param {string} password
 * @returns {Promise<LoginResponse>} - A promise that resolves to the LoginResponse
 */
const login = (email: string, password: string): Promise<LoginResponse> => {
  return apiClient
    .post<LoginResponse>("/api/login", {
      email,
      password,
    })
    .then((response) => response.data);
};

/**
 * Fetches current user data
 *
 * @return {Promise<User>} A promise that resolves with the fetched user data.
 */
const fetchUser = (): Promise<User> => {
  return apiClient.get<User>("/user").then((response) => response.data);
};

export { login, fetchUser };
