import { apiClient } from "@/services/api";
import { Account } from "@/interfaces/accounts.types";
import { Role } from "@/interfaces/auth.types";
/**
 * List all accounts.
 *
 * @returns {Promise<Account[]>} A promise that resolves to an array of accounts.
 */
const listAccounts = (): Promise<Account[]> => {
  return apiClient.get<Account[]>("accounts").then((response) => response.data);
};

/**
 * Retrieves an account by ID.
 *
 * @param {number} id - The ID of the account to retrieve.
 * @return {Promise<Account>} - A Promise that resolves to the retrieved account.
 */
const getAccount = (id: number): Promise<Account> => {
  return apiClient
    .get<Account>(`accounts/${id}`)
    .then((response) => response.data);
};

const getRoles = (): Promise<Role[]> => {
  return apiClient
    .get<Role[]>(`accounts/roles`)
    .then((response) => response.data);
};

/**
 * Creates a new account.
 *
 * @param {Account | Omit<Account, "id">} account - The account object to be created.
 * @returns {Promise<Account>} A promise that resolves to the created account object.
 */
const createAccount = (
  account: Account | Omit<Account, "id">,
): Promise<Account> => {
  return apiClient
    .post<Account>("accounts", account)
    .then((response) => response.data);
};

/**
 * Updates an account.
 *
 * @param {Account} account - The account object to be updated.
 * @returns {Promise<Account>} - A promise that resolves to the updated account object.
 */
const updateAccount = (account: Account): Promise<Account> => {
  return apiClient
    .put<Account>(`accounts/${account.id}`, account)
    .then((response) => response.data);
};

export { listAccounts, getAccount, createAccount, updateAccount, getRoles };
