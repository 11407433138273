<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog class="relative z-40 lg:hidden" @close="mobileMenuOpen = false">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex pt-10">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel
              class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl"
            >
              <div class="flex px-4 pb-2 pt-24 pb-10">
                <button
                  type="button"
                  class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                  @click="mobileMenuOpen = false"
                >
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div
                  v-for="page in menuItems"
                  :key="page.title"
                  class="flow-root"
                >
                  <router-link :to="page.route" @click="mobileMenuOpen = false">
                    <div class="-m-2 block p-2 font-medium text-gray-900">
                      {{ page.title }}
                    </div>
                  </router-link>
                </div>
              </div>

              <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div class="flow-root">
                  <a href="#" class="-m-2 block p-2 font-medium text-gray-900"
                    >Opret konto</a
                  >
                </div>
                <div class="flow-root">
                  <a href="#" class="-m-2 block p-2 font-medium text-gray-900"
                    >Log ind</a
                  >
                </div>
              </div>

              <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <!-- Currency selector -->
                <form>
                  <div class="inline-block">
                    <label for="mobile-currency" class="sr-only"
                      >Currency</label
                    >
                    <div
                      class="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white"
                    >
                      <select
                        id="mobile-currency"
                        name="currency"
                        class="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"
                      >
                        <option v-for="currency in currencies" :key="currency">
                          {{ currency }}
                        </option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center"
                      >
                        <ChevronDownIcon
                          class="h-5 w-5 text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <header class="fixed w-full" style="z-index: 1000">
      <nav aria-label="Top">
        <!-- Top navigation -->
        <div class="bg-gray-900">
          <div
            class="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8"
          >
            <!-- Currency selector -->
            <form class="hidden lg:block lg:flex-1">
              <div class="flex">
                <label for="desktop-currency" class="sr-only">Currency</label>
                <div
                  class="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white"
                >
                  <select
                    id="desktop-currency"
                    name="currency"
                    class="flex items-center rounded-md border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
                  >
                    <option v-for="currency in currencies" :key="currency">
                      {{ currency }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center"
                  >
                    <ChevronDownIcon
                      class="h-5 w-5 text-gray-300"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </div>
            </form>

            <div
              class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6"
            >
              <a
                href="#"
                class="text-sm font-medium text-white hover:text-gray-100"
                >Opret konto</a
              >
              <span class="h-6 w-px bg-gray-600" aria-hidden="true" />
              <a
                href="#"
                class="text-sm font-medium text-white hover:text-gray-100"
                >Log ind</a
              >
            </div>
          </div>
        </div>

        <!-- Secondary navigation -->
        <header class="bg-white">
          <nav
            class="mx-auto flex max-w-7xl items-center justify-between p-8 lg:px-8"
            aria-label="Global"
          >
            <div class="flex flex-1">
              <div class="hidden lg:flex lg:gap-x-12">
                <router-link
                  v-for="item in menuItemsLeft"
                  :key="item.title"
                  :to="item.route"
                  class="text-sm uppercase font-semibold leading-6 text-gray-900"
                  >{{ item.title }}
                </router-link>
              </div>
              <div class="flex lg:hidden">
                <button
                  type="button"
                  class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  @click="mobileMenuOpen = true"
                >
                  <span class="sr-only">Open main menu</span>
                  <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <a href="/" class="-m-1.5 p-1.5">
              <img
                height="25"
                class="h-5 sm:h-6 w-auto cursor-pointer"
                :src="logo"
              />
            </a>
            <div class="flex flex-1 justify-end">
              <div class="hidden lg:flex lg:gap-x-12 mr-6">
                <router-link
                  v-for="item in menuItems"
                  :key="item.title"
                  :to="item.route"
                  class="text-sm uppercase font-semibold leading-6 text-gray-900"
                  >{{ item.title }}
                </router-link>
              </div>

              <Popover class="ml-4 flow-root text-sm lg:relative lg:ml-2">
                <PopoverButton
                  class="group -m-2 flex items-center p-2 !outline-0"
                  @click="cartStore.setMiniCartStatus(true)"
                >
                  <ShoppingBagIcon
                    class="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span
                    class="ml-2 text-sm font-medium text-gray-700 group-hover:text-gray-800"
                    >{{ cartProducts.length }}</span
                  >
                </PopoverButton>
                <div>
                  <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition ease-in duration-150"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <PopoverPanel
                      class="absolute inset-x-0 top-28 border-t sm:border-0 mt-px bg-white pb-6 shadow-lg sm:px-2 lg:left-auto lg:right-0 lg:top-full lg:-mr-1.5 lg:mt-3 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5"
                    >
                      <h2 class="sr-only">Shopping Cart</h2>

                      <form class="mx-auto max-w-2xl px-4">
                        <ul role="list" class="divide-y divide-gray-200">
                          <li
                            v-for="product in cartProducts"
                            :key="product.id"
                            class="flex items-center py-6"
                          >
                            <div class="w-16">
                              <product-preview
                                :product="product"
                              ></product-preview>
                            </div>

                            <div class="ml-4 flex-auto">
                              <h3 class="font-medium text-gray-900">
                                <a :href="product.href">{{
                                  product.product_name
                                }}</a>
                              </h3>
                              <p class="text-gray-500">Hvid</p>
                            </div>
                          </li>
                        </ul>
                        <router-link to="/cart/checkout">
                          <button
                            type="submit"
                            class="w-full rounded-md border border-transparent bg-black px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-teal-700"
                          >
                            Til kassen
                          </button>
                        </router-link>
                        <p class="mt-3 text-center">
                          <router-link to="/cart">
                            <div
                              class="w-full rounded-md border px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-100"
                            >
                              Vis indkøbskurv
                            </div>
                          </router-link>
                        </p>
                      </form>
                    </PopoverPanel>
                  </transition>
                </div>
              </Popover>
            </div>
          </nav>
          <Dialog
            class="lg:hidden"
            @close="mobileMenuOpen = false"
            :open="mobileMenuOpen"
          >
            <div class="fixed inset-0 z-10" />
            <DialogPanel
              class="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-white px-6 py-6"
            >
              <div class="flex items-center justify-between">
                <div class="flex flex-1">
                  <button
                    type="button"
                    class="-m-2.5 rounded-md p-2.5 text-gray-700"
                    @click="mobileMenuOpen = false"
                  >
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <a href="#" class="-m-1.5 p-1.5">
                  <span class="sr-only">Your Company</span>
                  <img
                    class="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <div class="flex flex-1 justify-end">
                  <div class="hidden lg:flex lg:gap-x-12">
                    <a
                      v-for="item in menuItemsLeft"
                      :key="item.title"
                      :href="item.href"
                      class="text-base uppercase font-semibold leading-6 text-gray-900"
                      >{{ item.title }}</a
                    >sdsdsd
                  </div>
                  sad
                </div>
              </div>
              <div class="mt-6 space-y-2"></div>
            </DialogPanel>
          </Dialog>
        </header>
      </nav>
    </header>

    <div style="padding-top: 129px">
      <slot />
    </div>

    <section aria-labelledby="sale-heading" class="mt-28">
      <div class="overflow-hidden pt-32 sm:pt-14">
        <div class="bg-gray-800">
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="relative pb-16 pt-48 sm:pb-24">
              <div>
                <h2
                  id="sale-heading"
                  class="text-4xl font-bold tracking-tight text-white md:text-5xl"
                >
                  Følg Limitada
                  <br />
                  på Instagram
                </h2>
                <div class="mt-6 text-base">
                  <a href="#" class="font-semibold text-white">
                    Følg os her
                    <span aria-hidden="true"> →</span>
                  </a>
                </div>
              </div>

              <div
                class="absolute -top-32 left-1/2 -translate-x-1/2 transform sm:top-6 sm:translate-x-0"
              >
                <div
                  class="ml-24 flex min-w-max space-x-6 sm:ml-3 lg:space-x-8"
                >
                  <div
                    class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8"
                  >
                    <div class="flex-shrink-0">
                      <img
                        class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                        src="https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg"
                        alt=""
                      />
                    </div>

                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                      <img
                        class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                        src="https://tailwindui.com/img/ecommerce-images/home-page-03-category-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    class="flex space-x-6 sm:-mt-20 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8"
                  >
                    <div class="flex-shrink-0">
                      <img
                        class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                        src="https://tailwindui.com/img/ecommerce-images/home-page-03-favorite-01.jpg"
                        alt=""
                      />
                    </div>

                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                      <img
                        class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                        src="https://tailwindui.com/img/ecommerce-images/home-page-03-favorite-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    class="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 lg:space-y-8"
                  >
                    <div class="flex-shrink-0">
                      <img
                        class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                        src="https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg"
                        alt=""
                      />
                    </div>

                    <div class="mt-6 flex-shrink-0 sm:mt-0">
                      <img
                        class="h-64 w-64 rounded-lg object-cover md:h-72 md:w-72"
                        src="https://tailwindui.com/img/ecommerce-images/home-page-03-category-02.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer aria-labelledby="footer-heading" class="bg-white">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="border-t border-gray-200">
          <div class="pb-20 pt-16">
            <div class="md:flex md:justify-center text-3xl">Limitada</div>
            <div
              class="mx-auto mt-16 max-w-5xl xl:grid xl:grid-cols-2 xl:gap-8"
            >
              <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                <div
                  class="space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0"
                >
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">Produkter</h3>
                    <ul role="list" class="mt-6 space-y-6">
                      <li
                        v-for="item in footerNavigation.products"
                        :key="item.name"
                        class="text-sm"
                      >
                        <router-link
                          :to="item.href"
                          class="text-gray-500 hover:text-gray-600"
                          >{{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Kundetjeneste
                    </h3>
                    <ul role="list" class="mt-6 space-y-6">
                      <li
                        v-for="item in footerNavigation.customerService"
                        :key="item.name"
                        class="text-sm"
                      >
                        <router-link
                          :to="item.href"
                          class="text-gray-500 hover:text-gray-600"
                          >{{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0"
                >
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">Firma</h3>
                    <ul role="list" class="mt-6 space-y-6">
                      <li
                        v-for="item in footerNavigation.company"
                        :key="item.name"
                        class="text-sm"
                      >
                        <router-link
                          :to="item.href"
                          class="text-gray-500 hover:text-gray-600"
                          >{{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">Juridisk</h3>
                    <ul role="list" class="mt-6 space-y-6">
                      <li
                        v-for="item in footerNavigation.legal"
                        :key="item.name"
                        class="text-sm"
                      >
                        <router-link
                          :to="item.href"
                          class="text-gray-500 hover:text-gray-600"
                          >{{ item.name }}
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="lg:grid lg:grid-cols-2 lg:gap-x-6 xl:gap-x-8">
            <div class="flex items-center rounded-lg bg-gray-100 p-6 sm:p-10">
              <div class="mx-auto max-w-sm">
                <h3 class="font-semibold text-gray-900">Tilmeld nyhedsbrev</h3>
                <p class="mt-2 text-sm text-gray-500">
                  De seneste nyheder, produkter og limitied editions, sendt til
                  din indbakke ugentligt.
                </p>
                <form class="mt-4 sm:mt-6 sm:flex">
                  <label for="email-address" class="sr-only"
                    >Email address</label
                  >
                  <input
                    id="email-address"
                    type="text"
                    autocomplete="email"
                    required=""
                    class="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white px-4 py-2 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                  />
                  <div class="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                    <button
                      type="submit"
                      class="flex w-full items-center justify-center rounded-md border border-transparent bg-black px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white"
                    >
                      Tilmeld
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div
              class="relative mt-6 flex items-center px-6 py-12 sm:px-10 sm:py-16 lg:mt-0"
            >
              <div class="absolute inset-0 overflow-hidden rounded-lg">
                <img
                  :src="footerImage"
                  alt=""
                  class="h-full w-full object-cover object-center saturate-0 filter"
                />
                <div class="absolute inset-0 bg-black bg-opacity-80" />
              </div>
              <div class="relative mx-auto max-w-sm text-center">
                <h3 class="text-2xl font-bold tracking-tight text-white">
                  Vil du sælge dine prints?
                </h3>
                <p class="mt-2 text-gray-200">
                  Er du en dygtig og kreativ sjæl så hører vi gerne fra dig.
                  <router-link
                    to="/about/for-artists"
                    class="whitespace-nowrap font-bold text-white hover:text-gray-200"
                    >Ansøg her<span aria-hidden="true">
                      &rarr;</span
                    ></router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="py-10 md:flex md:items-center md:justify-between">
          <div class="text-center md:text-left">
            <p class="text-sm text-gray-500">
              &copy; 2024 Limitada ApS. Alle rettigheder forbeholdes.
            </p>
          </div>

          <div class="mt-4 flex items-center justify-center md:mt-0">
            <div class="flex space-x-8">
              <router-link
                v-for="item in footerNavigation.bottomLinks"
                :key="item.name"
                :to="item.href"
                class="text-sm text-gray-500 hover:text-gray-600"
                >{{ item.name }}
              </router-link>
            </div>

            <div class="ml-6 border-l border-gray-200 pl-6">
              <a
                href="#"
                class="flex items-center text-gray-500 hover:text-gray-600"
              >
                <img
                  src="https://tailwindui.com/img/flags/flag-danmark.svg"
                  alt=""
                  class="h-auto w-5 flex-shrink-0"
                />
                <span class="ml-3 text-sm">Byt land</span>
                <span class="sr-only">location and currency</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <NotificationGroup group="foo">
      <div
        class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
      >
        <div class="w-full max-w-sm">
          <Notification
            v-slot="{ notifications }"
            enter="transform ease-out duration-300 transition"
            enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
            enter-to="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
            move="transition duration-500"
            move-delay="delay-300"
          >
            <div
              class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <div class="flex items-center justify-center w-12 bg-green-600">
                <svg
                  class="w-6 h-6 text-white fill-current"
                  viewBox="0 0 40 40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                  />
                </svg>
              </div>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3">
                  <span class="font-semibold text-sm text-green-700">{{
                    notification.title
                  }}</span>
                  <p class="text-sm text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
    <NotificationGroup group="cart">
      <div
        class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none"
      >
        <div class="w-full max-w-sm">
          <Notification
            v-slot="{ notifications }"
            enter="transform ease-out duration-300 transition"
            enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
            enter-to="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-500"
            leave-from="opacity-100"
            leave-to="opacity-0"
            move="transition duration-500"
            move-delay="delay-300"
          >
            <div
              class="flex w-full flex-col items-center space-y-4 sm:items-end mb-2"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
              <transition
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <div
                  class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                >
                  <div class="p-4">
                    <div class="flex items-start">
                      <div class="flex-shrink-0">
                        <CheckCircleIcon
                          class="h-6 w-6 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="ml-3 w-0 flex-1 pt-0.5">
                        <p class="text-sm font-medium text-gray-900">
                          {{ notification.title }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ notification.text }}
                        </p>
                        <div class="mt-3 flex space-x-7">
                          <router-link to="/cart" class="inline-block">
                            <button
                              type="button"
                              class="rounded-md bg-white text-sm font-medium text-teal-600 hover:text-teal-500 focus:outline-none"
                            >
                              Vis indkøbskurv
                            </button>
                          </router-link>
                          <router-link to="/cart/checkout" class="inline-block">
                            <button
                              type="button"
                              class="rounded-md bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none"
                            >
                              Til kassen
                            </button>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
  </div>
</template>

<script setup lang="ts">
import { MenuItem as NavMenuItem } from "@/interfaces/layout.types";
import footerImage from "@/assets/img/footer.jpg";
import logo from "@/assets/img/logo.png";
import { computed, onMounted, ref, watch } from "vue";

import ProductPreview from "@/components/ProductPreview.vue";
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  UserIcon,
  XMarkIcon,
  CheckCircleIcon,
} from "@heroicons/vue/24/outline";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { useCartStore } from "@/store/cart";

const cartStore = useCartStore();

const currencies = ["DKK", "USD", "EUR", "SEK", "NOK"];
const menuItems: NavMenuItem[] = [
  {
    name: "artists",
    title: "Om Limitada",
    route: "/about",
    roles: ["user"],
    classes: "ml-3",
  },
  {
    name: "artists",
    title: "Kontakt",
    route: "/about/contact",
    roles: ["user"],
    classes: "",
  },
];

const menuItemsLeft: NavMenuItem[] = [
  {
    name: "products",
    title: "Hoodies",
    route: "/products/hoodies",
    roles: ["user"],
    classes: "",
  },
  {
    name: "products",
    title: "T-Shirts",
    route: "/products/tshirts",
    roles: ["user"],
    classes: "",
  },
  {
    name: "artists",
    title: "Kunstnere",
    route: "/artists",
    roles: ["user"],
    classes: "border-l !pl-5 !pr-5 border-r ml-3",
  },
];

const footerNavigation = {
  products: [
    { name: "Hoodies", href: "#" },
    { name: "T-shirts", href: "#" },
  ],
  customerService: [
    { name: "Kontakt", href: "/about/contact" },
    { name: "Levering", href: "/about/delivery" },
    { name: "Reklamation", href: "/about/complain" },
    { name: "Returpolitik", href: "/about/return" },
  ],
  company: [
    { name: "Hvem vi er", href: "/about" },
    { name: "For artister", href: "/about/for-artists" },
  ],
  legal: [{ name: "Privatlivspolitik", href: "/about/legal" }],
};

const showMiniCart = ref(false);
watch(
  () => cartStore.miniCartStatus,
  (newStatus) => {
    if (newStatus) {
      showMiniCart.value = newStatus;
    }
  },
);

const mobileMenuOpen = ref(false);

// Ensure cartStore.cart and cartStore.cart.products are defined
onMounted(() => {
  if (!cartStore.cart) {
    cartStore.cart = { products: [] };
  }
});

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Company", href: "#" },
];

const cartProducts = computed(() => cartStore.cart?.products || []);
</script>
