<template>
  <div>
    <div
      class="relative bg-gray-50 rounded-md w-full aspect-w-14 aspect-h-16 overflow-hidden"
    >
      {{ imageOne }}
      <img
        :src="imageOne"
        class="absolute inset-0 w-full h-full object-cover object-center transition-transform duration-500"
      />
      <img
        :src="product.product_image"
        class="absolute inset-0 w-full h-full object-contain"
        style="width: 40%; height: auto; top: 32%; left: 30%"
      />
    </div>
  </div>
  <h4 class="sr-only">Available colors</h4>
</template>

<script setup lang="ts">
import { ref, computed, defineProps } from "vue";

import backgroundImageShirtBackBlackWomen from "@/assets/img/clothes/tshirt-back-black-women.jpg";
import backgroundImageShirtFrontBlackWomen from "@/assets/img/clothes/tshirt-front-black-women.jpg";

import backgroundImageHoodieBackBlackWomen from "@/assets/img/clothes/hoodie-back-black-women.jpg";
import backgroundImageHoodieFrontBlackWomen from "@/assets/img/clothes/hoodie-front-black-women.jpg";

import backgroundImageShirtBackWhiteWomen from "@/assets/img/clothes/tshirt-back-white-women.jpg";
import backgroundImageShirtFrontWhiteWomen from "@/assets/img/clothes/tshirt-front-white-women.jpg";

import backgroundImageShirtBackBlackMan from "@/assets/img/clothes/tshirt-back-black-man.jpg";
import backgroundImageShirtFrontBlackMan from "@/assets/img/clothes/tshirt-front-black-man.jpg";

import backgroundImageHoodieBackBlackMan from "@/assets/img/clothes/hoodie-back-black-man.jpg";
import backgroundImageHoodieFrontBlackMan from "@/assets/img/clothes/hoodie-front-black-man.jpg";

import backgroundImageShirtBackWhiteMan from "@/assets/img/clothes/tshirt-back-white-man.jpg";
import backgroundImageShirtFrontWhiteMan from "@/assets/img/clothes/tshirt-front-white-man.jpg";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const currentImage = ref(0);

const selectedGender = ref("Dame");
const imageOne = computed(() => {
  if (props.product.attributes.color === "Hvid") {
    if (selectedGender.value?.name === "Dame") {
      return props.product.attributes.type === "Hoodie"
        ? backgroundImageHoodieBackBlackWomen
        : backgroundImageShirtBackWhiteWomen;
    } else {
      return props.product.attributes.type === "Hoodie"
        ? backgroundImageHoodieBackBlackMan
        : backgroundImageShirtBackWhiteMan;
    }
  } else {
    if (selectedGender.value?.name === "Dame") {
      return props.product.attributes.type === "Hoodie"
        ? backgroundImageHoodieBackBlackWomen
        : backgroundImageShirtBackBlackWomen;
    } else {
      return props.product.attributes.type === "Hoodie"
        ? backgroundImageHoodieBackBlackMan
        : backgroundImageShirtBackBlackMan;
    }
  }
});
</script>

<style scoped>
img {
  transition: transform 0.5s ease-in-out;
}

span {
  height: 4px;
}
</style>
