import { defineStore } from "pinia";
import { getCart, removeProductFromCart } from "@/services/api/cart";

export const useCartStore = defineStore("cart", {
  state: () => ({
    cart: {},
    miniCartOpen: false,
    loading: false,
  }),
  actions: {
    async getCart() {
      this.setLoading(true);
      try {
        this.cart = await getCart();
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      } finally {
        this.setLoading(false);
      }
    },

    async removeItem(id: number) {
      await removeProductFromCart(id);
    },

    setCart(cart: any) {
      this.cart = cart;
    },

    clear() {
      this.cart = {};
    },

    setMiniCartStatus(isOpen: boolean) {
      this.miniCartOpen = isOpen;
    },
    setLoading(isLoading: boolean) {
      this.loading = isLoading;
    },
  },
  persist: {
    enabled: true,
  },
});
