import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { useAuthStore } from "@/store/auth";
import { defineComponent } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      template: "default",
      public: false,
      roles: ["admin"],
      permissions: ["read:home"],
    },
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "@/views/dashboard/DashboardView.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "login-view" */ "../views/LoginView.vue"),
  },
  {
    path: "/about/complain",
    name: "complain",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "complain-view" */ "../views/about/ComplainView.vue"
      ),
  },
  {
    path: "/about/return",
    name: "return",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "return-view" */ "../views/about/ReturnView.vue"
      ),
  },
  {
    path: "/about/legal",
    name: "legal",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/about/PersonalInformationView.vue"
      ),
  },
  {
    path: "/about/for-artists",
    name: "for-artists",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/about/ForArtists.vue"
      ),
  },
  {
    path: "/about/delivery",
    name: "delivery",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/about/DeliveryView.vue"
      ),
  },
  {
    path: "/about/contact",
    name: "contact",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/about/ContactView.vue"
      ),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "invoices" */ "../views/about/AboutView.vue"),
  },

  {
    path: "/product/:id",
    name: "products.view",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-edit" */ "../views/products/SingleProductView.vue"
      ),
  },
  {
    path: "/artists",
    name: "artist.index",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-edit" */ "../views/artist/ArtistsView.vue"
      ),
  },
  {
    path: "/artist/:slug",
    name: "artist.view",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-edit" */ "../views/artist/SingleArtistView.vue"
      ),
  },
  {
    path: "/products/:category",
    name: "products.category",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/products/ProductsView.vue"
      ),
    props: true,
  },
  {
    path: "/products/new-arrivals",
    name: "products.arrivals",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "../views/products/ProductsView.vue"
      ),
  },
  {
    path: "/cart",
    name: "cart",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/cart/CartView.vue"),
  },
  {
    path: "/cart/checkout",
    name: "checkout",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(/* webpackChunkName: "orders" */ "@/views/cart/CheckoutView.vue"),
  },
  {
    path: "/my-account",
    name: "account",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "myaccount" */ "../views/accounts/MyAccountView.vue"
      ),
  },
  {
    path: "/accounts",
    name: "accounts",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts" */ "../views/accounts/AccountsView.vue"
      ),
  },
  {
    path: "/accounts/new",
    name: "accounts.create",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-new" */ "../views/accounts/AccountsCreateUpdateView.vue"
      ),
  },
  {
    path: "/accounts/edit/:id",
    name: "accounts.edit",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-edit" */ "../views/accounts/AccountsCreateUpdateView.vue"
      ),
  },
  {
    path: "/my-account",
    name: "accounts.own",
    meta: {
      template: "auth",
      public: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "accounts-my" */ "../views/accounts/MyAccountView.vue"
      ),
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      defineComponent({
        template: "<div></div>",
      }),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      const authStore = useAuthStore();
      console.log("Logging out");
      authStore.logout();
      next({ name: "login" });
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve({
            left: savedPosition.left,
            top: savedPosition.top,
            behavior: "smooth",
          });
        } else {
          resolve({
            left: 0,
            top: 0,
            behavior: "smooth",
          });
        }
      }, 0);
    });
  },
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    next();
    // const auth = useAuthStore();

    // if (!to.meta.public && !auth.isAuthenticated) {
    //   return next({ path: '/login' });
    // }
    // next();
  },
);

export default router;
