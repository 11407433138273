import { apiClient } from "@/services/api";
import { notify } from "notiwind";
import { useCartStore } from "@/store/cart";

/**
 * Fetches the membership products from the API.
 *
 * @function getMembershipProducts
 * @returns {Promise} A promise that resolves to an array of membership products.
 */
const getCart = () => {
  return apiClient
    .get("api/cart/get", {})
    .then((response) => response.data.data);
};

const addProductToCart = (name: string, id: number, options: object) => {
  const cartStore = useCartStore();
  return apiClient.post("api/cart/add/" + id, options).then((response) => {
    cartStore.setCart(response.data.data);
    cartStore.setMiniCartStatus(true);
  });
};
const removeProductFromCart = (id: number) => {
  return apiClient
    .get("api/cart/remove/" + id, {})
    .then((response) => response.data.data);
};
export { getCart, addProductToCart, removeProductFromCart };
